import * as React from "react";
import { Container } from "../container/Container";
import { Row } from "../row/Row";
import Image from "gatsby-image";

import s from "./Home.scss";
import { Button } from "../button/Button";

interface Resume {
  className?: string;
  previewImage: any;
  mailPreviewImage: any;
  //   children: React.ReactNode;
}

export const Home = ({ previewImage, mailPreviewImage }: Resume) => (
  <>
    <div className={s.view}>
      <Container>
        <Row className={`${s.column_reverse__m}`}>
          <article
            className={`${s.main_text} ${s.col__4} ${s.col__12__m} ${s.margin__ts__m}`}
          >
            <div className={s.title}>
              <h1>Start met onze gratis Portfolio Tracker</h1>{" "}
              <span>(beta)</span>
            </div>
            <div className={`${s.margin__t} ${s.margin__ts__m}`}>
              <Button
                target="_self"
                type="primary"
                to="https://app.stockdash.nl/signup"
              >
                Gratis aanmelden
              </Button>
              <a className={`${s.small_link}`} href="https://app.stockdash.nl">
                Heeft u al een account?
              </a>
            </div>
          </article>
          <div
            className={`${s.col__8} ${s.v_center} ${s.col__12__m}`}
            style={{ position: "relative" }}
          >
            <div className={s.image_container}>
              <div style={{ width: "100%" }}>
                {previewImage && (
                  <Image
                    className={s.image_preview}
                    title="Product preview"
                    alt="Product preview"
                    fluid={previewImage.fluid}
                    fadeIn={true}
                  />
                )}
              </div>
            </div>
          </div>
        </Row>
      </Container>

      <Container className={s.grow}>
        <Row className={s.first_content}>
          <section
            className={`${s.col__4} ${s.divider} ${s.col__12__m} ${s.column}`}
          >
            <h3>Bekijk uw portfolio in detail</h3>
            <br />
            <p>
              Dankzij onze web applicatie krijgt u meer inzicht in uw
              beleggingen. In de app kunt u onder andere zien hoe groot een
              aandeel is ten opzichte van uw totale portfolio. We voegen
              maandelijks nog nieuwe functionaliteiten toe!
            </p>
            <a href="https://support.stockdash.nl/kb/guide/nl/deel-uw-idee-WJKLfkp3ug/Steps/137915">
              Heeft u een tip voor ons?
            </a>
          </section>
          <section
            className={`${s.col__5} ${s.divider} ${s.col__12__m} ${s.margin__ts__m} ${s.column} `}
          >
            <div className={s.center_column}>
              <h3>Ontvang maandelijkse updates</h3> <br />
              <p>
                Iedere maand ontvang u automatisch een update hoe uw portfolio
                het de afgelopen maand heeft gedaan ten opzichte van de markt.
                We vergelijken uw portfolio groei met dat van bekende indexen &
                etfs.
              </p>
              <br></br>
              <span className={s.small_text}>Voorbeeld</span>
              <div style={{ width: "100%" }}>
                {mailPreviewImage && (
                  <Image
                    title="Maandelijkse mail update"
                    alt="Maandelijkse mail update"
                    fluid={mailPreviewImage.fluid}
                    fadeIn={false}
                  />
                )}
              </div>
            </div>
          </section>
          <section
            className={`${s.col__3} ${s.col__12__m} ${s.margin__ts__m} ${s.column}`}
          >
            <h3>Nieuws</h3> <br />
            <p>Publieke beta inschrijvingen zijn geopend.</p>{" "}
            <span className={s.small_text}>25 januari 2021</span>
          </section>
        </Row>
      </Container>
    </div>
    <footer className={s.footer}>
      <Container>
        <Row className={s.footer__inner}>
          <section className={`${s.col__6} ${s.divider} ${s.col__12__m} `}>
            <span className={`bold ${s.footer__inner__header}`}>Contact</span>
            <Row className={s.margin__ts}>
              <section className="col__12">
                <span>kvk 77041445 (Wemble Software)</span>
              </section>
              <section className="col__12">
                <a href="https://support.stockdash.nl">Support nodig?</a>
              </section>
              {/* <section className="col__7">iets</section> */}
            </Row>
          </section>
          <section className="col__6 col__12__m"></section>
        </Row>
      </Container>
    </footer>
  </>
);
