import * as React from "react";
import Helmet from "react-helmet";
import { Home } from "../components/home/Home";
import { graphql } from "gatsby";

export default ({ data }: { data: any }) => {
  return (
    <>
      <Helmet title="Home" />
      <Home
        previewImage={data.previewImage.childImageSharp}
        mailPreviewImage={data.mailPreviewImage.childImageSharp}
      ></Home>
    </>
  );
};

export const pageQuery = graphql`
  query PreviewImage {
    previewImage: file(relativePath: { regex: "/preview4/" }) {
      childImageSharp {
        fluid {
          src
          sizes
          srcSet
          srcWebp
          aspectRatio
        }
      }
    }

    mailPreviewImage: file(relativePath: { regex: "/mail-update-preview/" }) {
      childImageSharp {
        fluid {
          src
          sizes
          srcSet
          srcWebp
          aspectRatio
        }
      }
    }
  }
`;
